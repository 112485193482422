import { Link } from 'gatsby';
import React, { useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import RiscoPT from '../../images/pdf/risco/Política de Gestão de Risco de Liquidez - Hub Fintech.pdf';
import RiscoEN from '../../images/pdf/risco/Política de Gestão de Risco de Liquidez - Hub Fintech.pdf';
import RiscoES from '../../images/pdf/risco/Política de Gestão de Risco de Liquidez - Hub Fintech.pdf'
import LogoHub from '../../images/svg/Logo-hubfintech-branco.svg';
import Contabeis from '../../images/pdf/contabeis/Demonstrações Contábeis Hub Pagamentos 30.06.2021.pdf'
import Contabeis2 from '../../images/pdf/contabeis/Demonstrações Contábeis Hub Pagamentos 31_12_2021.pdf'
import Contabeis3 from '../../images/pdf/contabeis/Demonstrações Contábeis Hub 30_06_2022.pdf'
import Contabeis4 from '../../images/pdf/contabeis/Demonstrações Contábeis Hub Pagamentos 31_12_2022.pdf'
import Contabeis5 from '../../images/pdf/contabeis/Demonstrações contábeis Hub junho_2023.pdf'
import Contabeis6 from '../../images/pdf/contabeis/DEMONSTRACOES_CONTABEIS_HUB_2023SEMESTRE2.pdf'
import Contabeis7 from '../../images/pdf/contabeis/DEMONSTRACOES_CONTABEIS_HUB_2024SEMESTRE1.pdf'
import PLD from '../../images/pdf/pld/PLD_FT.pdf'
import PoliticaConformidade from '../../images/pdf/politicaConformidade/politica_de_conformidade.pdf'
import {
  Atendimento,
  Carreira,
  Container,
  HubFintech,
  MapSiteLinks,
} from './styles';

export default function Footer() {
  const { idioma } = useContext(LanguageContext);

  const company = [
    'Termos, Políticas e Resultados',
    'Terms, Policies and Results',
    'Términos, políticas y resultados',
  ];
  const privacidade = ['Privacidade', 'Privacy', 'Privacidad'];
  const rates = ['Tarifas', 'Rates', 'Tarifas'];
  const conversion = ['Conversão', 'Conversion', 'Conversión'];
  const denuncia = [
    'Compliance e Canal de Denúncias',
    'Compliance and Reporting Channel',
    'Cumplimiento y Canal de Notificación'
  ];
  const service = [
    'Canais de Atendimento',
    'Service channels',
    'Canales de servicio'
  ];
  
  const risk = ['Gestão de Riscos', 'Risk Management', 'Gestión de Riesgos'];

  const accounting = ['Demonstrações contábeis 1º sem. 2021', 'Accounting statements 1st half of 2021', 'Demostraciones contables 1er Sem. 2021']
  const accounting2 = ['Demonstrações contábeis 2º sem. 2021', 'Accounting statements 2nd half of 2021', 'Demostraciones contables 2º Sem. 2021']
  const accounting3 = ['Demonstrações contábeis 1º sem. 2022', 'Accounting statements 1st half of 2022', 'Demostraciones contables 1er Sem. 2022']
  const accounting4 = ['Demonstrações contábeis 2º sem. 2022', 'Accounting statements 2nd half of 2022', 'Demostraciones contables 2º Sem. 2022']
  const accounting5 = ['Demonstrações contábeis 1º sem. 2023', 'Accounting statements 1st half of 2023', 'Demostraciones contables 1º Sem. 2023']
  const accounting6 = ['Demonstrações contábeis 2º sem. 2023', 'Accounting statements 2nd half of 2023', 'Demostraciones contables 2º Sem. 2023']
  const accounting7 = ['Demonstrações contábeis 1º sem. 2024', 'Accounting statements 1nd half of 2024', 'Demostraciones contables 1º Sem. 2024']

  const career = ['Vem ser Hub', 'Come be Hub', 'Ven a ser Hub'];
  const carreiras = [
    'Página de carreiras',
    'Careers page',
    'Página de carreras',
  ];

  const ombudsman = ['Ouvidoria', 'Ombudsman', 'Defensoría'];
  const atendimentoEmpresa = ['Atendimento empresa', 'Company service', 'Servicio empresarial'];
  const atendimento = ['Atendimento usuário', 'User service', 'Servicio al usuario' ];
  const atendimentoWhatsapp = ['Atendimento whatsapp', 'Whatsapp service', 'Servicio al whatsapp' ];
  const atendimentoEmail = ['Atendimento via e-mail', 'Service via email', 'Servicio por correo electrónico'];

  const risco = [RiscoPT, RiscoEN, RiscoES]
  
  const pld = ['PLD/FT', 'PLD/FT', 'PLD/FT']

  const date = new Date();

  const sac = ['SAC – Serviço de Atendimento ao Consumidor', 'SAC - Customer Service', 'SAC - Atención al Cliente'];

  const politicaConformidade = ['Política de Conformidade', 'Compliance Policy', 'Política de cumplimiento']

  const securityAndPrivacy = ['Segurança e Privacidade', 'Security and Privacy', 'Seguridad y privacidad']
  
  const cybersecurity = ['Segurança Cibernética', 'Cybersecurity', 'Seguridad cibernética']

  return (
    <Container>
      <HubFintech>
        <img src={LogoHub} alt="Logo hub fintech" />
        <div>
          <p>Hub Fintech - Copyright © {date.getFullYear()} </p>
          <p>CNPJ 13.884.775/0001-19 </p>
          <input type="hidden" name="hub_version" value="1.3.1" />
        </div>
      </HubFintech>
      <MapSiteLinks>
        <li>
          <p>
            <b>{company[idioma]}</b>
          </p>
        </li>
        <div>
          <div>
            <li>
              <p>
                <Link to="/privacidade">{privacidade[idioma]}</Link>
              </p>
            </li>
            <li>
              <p>
                <Link to="/tarifas">{rates[idioma]}</Link>
              </p>
            </li>
            <li>
              <p>
                <Link to="/conversao">{conversion[idioma]}</Link>
              </p>
            </li>
            <li>
              <p>
                <Link to="/denuncias">{denuncia[idioma]}</Link>
              </p>
            </li>
            <li>
              <p>
                <Link to="/seguranca-cibernetica">{cybersecurity[idioma]}</Link>
              </p>
            </li>
            <li>
              <p>
                <a href={PLD} download>
                  {pld[idioma]}
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href={PoliticaConformidade} download>
                  {politicaConformidade[idioma]}
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href='https://especiais.magazineluiza.com.br/seguranca/' target='new'>
                  {securityAndPrivacy[idioma]}
                </a>
              </p>
            </li>
            <li>
              <p>
                <Link to="/ouvidoria">{ombudsman[idioma]}</Link>
              </p>
            </li>
            <li>
              <a href={risco[idioma]} download>
              <p>
                <a href={risco[idioma]} download>
                  {risk[idioma]}
                </a>
              </p>
              </a>
            </li>
            <li>
              <a href={Contabeis} download>
              <p>
                <a href={Contabeis} download>
                  {accounting[idioma]}
                </a>
              </p>
              </a>
            </li>
            <li>
              <a href={Contabeis2} download>
              <p>
                <a href={Contabeis2} download>
                  {accounting2[idioma]}
                </a>
              </p>
              </a>
            </li>
            <li>
              <a href={Contabeis3} download>
              <p>
                <a href={Contabeis3} download>
                  {accounting3[idioma]}
                </a>
              </p>
              </a>
            </li>
            <li>
              <a href={Contabeis4} download>
              <p>
                <a href={Contabeis4} download>
                  {accounting4[idioma]}
                </a>
              </p>
              </a>
            </li>
            <li>
              <a href={Contabeis5} download>
              <p>
                <a href={Contabeis5} download>
                  {accounting5[idioma]}
                </a>
              </p>
              </a>
            </li>
            <li>
              <a href={Contabeis6} download>
              <p>
                <a href={Contabeis6} download>
                  {accounting6[idioma]}
                </a>
              </p>
              </a>
            </li>
            <li>
              <a href={Contabeis7} download>
              <p>
                <a href={Contabeis7} download>
                  {accounting7[idioma]}
                </a>
              </p>
              </a>
            </li>
          </div>
        </div>
      </MapSiteLinks>
      <Carreira>
        <li>
          <p>
            <b>{career[idioma]}</b>
          </p>
        </li>
        <div>
          <li>
            <p>
              <a
                onClick={() =>
                  window.open('https://jobs.kenoby.com/hubfintech')
                }
              >
                {carreiras[idioma]}
              </a>
            </p>
          </li>
          <li>
            <p>
              <a
                onClick={() =>
                  window.open('https://www.linkedin.com/company/hubfintech/')
                }
              >
                Linkedin
              </a>
            </p>
          </li>
        </div>
      </Carreira>
      <Atendimento>
        <li>
          <p>
            <b className="title">{service[idioma]}</b>
          </p>
        </li>
        <div>
          <div>
            <li>
              <p>{atendimentoEmpresa[idioma]}</p>
            </li>
            <li>
              <p>
                <b>11 2050-3898 </b>
              </p>
            </li>
          </div>
          <div>
            <li>
              <p>{atendimento[idioma]}</p>
            </li>
            <li>
              <p>
                <b>11 2050-3887</b>
              </p>
            </li>
          </div>
          <div>
            <li>
              <p>{atendimentoWhatsapp[idioma]}</p>
            </li>
            <li>
              <p>
                <b>11 2050-3865</b>
              </p>
            </li>
          </div>
          <div className="atendimento-email">
            <li>
              <p>{atendimentoEmail[idioma]}</p>
            </li>
            <li>
              <p>
                <b>contaaqui@hubfintech.com.br</b>
              </p>
            </li>
          </div>
        </div>
        <li>
          <p>
            <a href='https://www.magalupay.com.br/sac/' target='_blank'>{sac[idioma]}</a>
          </p>
        </li>
      </Atendimento>
      {/* <Hours>
        
      </Hours> */}
    </Container>
  );
}
